import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import './index.css'
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if(process.env.REACT_APP_SENTRY_DSN){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.2,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

const ErrorFallback:Sentry.FallbackRender = ({ error, componentStack }) => {
  console.log(error, componentStack)
  return (
    <div>
      <h1>Oops! Something went wrong.</h1>
      <p>Error: {(error as any)?.message}</p>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)