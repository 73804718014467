import React, { useEffect, useReducer, useCallback } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { getRequest, postRequest } from '../../api'
import {
  IRegistrationContext,
  IDriver,
  IBooking,
} from '../../interfaces/Registration'
import Loader from './Loader'
import {
  IOrder,
  IOrderLineItem,
  IRefund,
  IRefundLineItem,
} from 'shopify-api-node'
import { HeaderLogo } from './HeaderLogo'
import { SCREEN_NAMES } from './Constants'
import { sentryException } from '../../helper/sentry'

type Props = {
  isGenericDriverRegistration?: boolean
}

// Define action types
export type TActionType =
  | {
      type: 'SET_LOADING'
      payload: {
        loading: boolean
      }
    }
  | {
      type: 'SET_IS_JUNIOR'
      payload: {
        isJunior: boolean
      }
    }
  | {
      type: 'SET_EMAIL'
      payload: {
        email: string
      }
    }
  | {
      type: 'SET_DRIVERS'
      payload: {
        drivers: IDriver[] | []
      }
    }
  | {
      type: 'SET_REGISTRATION_DRIVER'
      payload: {
        driver: IDriver
      }
    }
  | {
      type: 'SET_ORDER'
      payload: {
        order: IOrder
      }
    }
  | {
      type: 'SET_SELECTED_DOB'
      payload: {
        selectedDOB: {
          day: string
          month: string
          year: string
        }
      }
    }
  | {
      type: 'SET_BOOKING'
      payload: {
        booking: IBooking
      }
    }
  | {
      type: 'SET_REGISTRATION_QR_LINK'
      payload: {
        itemRegistrationLink: string
      }
    }
  | {
      type: 'SET_CURRENT_NAVIGATION'
      payload: {
        navigation: {
          currentScreen: string
          previousScreen: string
        }
      }
    }
  | {
      type: 'SET_EDIT_MODE'
      payload: {
        editMode: boolean
      }
    }
  | {
      type: 'SET_TOTAL_LINE_ITEMS'
      payload: {
        totalLineItems: number
      }
    }
  | {
      type: 'SET_IS_CHILD_FORM_HAS_ERROR'
      payload: {
        hasError: boolean
      }
    }
  | {
      type: 'SET_SELECTED_DOB_ERROR'
      payload: {
        selectedDOBError: string
      }
    }

export interface IRegistrationStateValue extends IRegistrationContext {
  dispatch: React.Dispatch<TActionType>
  itemRegistrationLink: string
  navigation: {
    currentScreen: string
    previousScreen: string
  }
  juniors: IDriver[]
  editMode: boolean
  totalLineItems: number
  hasError: boolean
  selectedDOB: {
    day: string
    month: string
    year: string
  }
  selectedDOBError: string
}

// Define initial state
const initialState: IRegistrationStateValue = {
  orderId: '',
  order: {} as IOrder,
  booking: {} as IBooking,
  drivers: [] as IDriver[],
  juniors: [] as IDriver[],
  driver: { email_consent: true } as IDriver,
  isJunior: false,
  editMode: false,
  loading: false, // Add this line
  isGenericDriverRegistration: false,
  toggleIsJunior: () => {},
  email: '',
  setEmail: () => {},
  getDrivers: async () => [],
  getBooking: async () => {},
  setLoading: function (isLoading: boolean): void {
    throw new Error('Function not implemented.')
  },
  dispatch: () => {},
  itemRegistrationLink: '',
  navigation: {
    currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
    previousScreen: '',
  },
  totalLineItems: 0,
  hasError: false,
  selectedDOB: {
    day: '',
    month: '',
    year: '',
  },
  selectedDOBError: '',
}

export const RegistrationContext =
  React.createContext<IRegistrationStateValue | null>(null)

// Define reducer function
const registrationReducer = (
  state: IRegistrationStateValue,
  action: TActionType
) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload.loading }
    case 'SET_IS_JUNIOR':
      return { ...state, isJunior: action.payload.isJunior }
    case 'SET_EMAIL':
      return { ...state, email: action.payload.email }
    case 'SET_DRIVERS':
      return { ...state, drivers: action.payload.drivers }
    case 'SET_ORDER':
      return { ...state, order: action.payload.order }
    case 'SET_SELECTED_DOB':
      return { ...state, selectedDOB: action.payload.selectedDOB }
    case 'SET_BOOKING':
      return { ...state, booking: action.payload.booking }
    case 'SET_REGISTRATION_DRIVER':
      return { ...state, driver: action.payload.driver }
    case 'SET_REGISTRATION_QR_LINK':
      return {
        ...state,
        itemRegistrationLink: action.payload.itemRegistrationLink,
      }
    case 'SET_CURRENT_NAVIGATION':
      return {
        ...state,
        navigation: action.payload.navigation,
      }
    case 'SET_EDIT_MODE':
      return { ...state, editMode: action.payload.editMode }
    case 'SET_IS_CHILD_FORM_HAS_ERROR':
      return {
        ...state,
        hasError: action.payload.hasError,
      }
    case 'SET_SELECTED_DOB_ERROR':
      return { ...state, selectedDOBError: action.payload.selectedDOBError }
    default:
      return state
  }
}

const RegistrationBaseWrapper = (props: Props) => {
  const { isGenericDriverRegistration } = props
  const [state, dispatch] = useReducer(registrationReducer, initialState)

  const { loading, isJunior, email, drivers, order, booking } = state
  const { bookingId } = useParams()

  // Action creators
  const setLoading = (value: boolean) => {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: value,
      },
    })
  }

  const toggleIsJunior = (value: boolean) => {
    dispatch({
      type: 'SET_IS_JUNIOR',
      payload: {
        isJunior: value,
      },
    })
  }

  const setEmail = (value: string) => {
    dispatch({
      type: 'SET_EMAIL',
      payload: {
        email: value,
      },
    })
  }

  const setDrivers = (value: IDriver[] | []) => {
    const updateDriverGender = value.map((newDriver: IDriver) => {
      if (!newDriver.gender) {
        return { ...newDriver, gender: 'Prefer not to disclose' }
      }
      return newDriver
    })
    dispatch({
      type: 'SET_DRIVERS',
      payload: {
        drivers: updateDriverGender,
      },
    })
  }

  const setOrder = (value: IOrder) => {
    dispatch({
      type: 'SET_ORDER',
      payload: {
        order: value,
      },
    })
  }

  const setBooking = (value: IBooking) => {
    dispatch({
      type: 'SET_BOOKING',
      payload: {
        booking: value,
      },
    })
  }

  function formatDate(dateString: string): string {
    const date = new Date(dateString)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()
    return `${day}-${month}-${year}`
  }

  const getDrivers = async () => {
    const { data } = await postRequest('getDrivers', {
      data: {
        email: email,
        isJunior: isJunior,
      },
    })

    const driversResponse = data.result.drivers as IDriver[]
    console.log('==== driversResponse ====', driversResponse)
    const driversFormat = driversResponse.map((driver: IDriver) => {
      if (driver.guardian_dob) {
        return {
          ...driver,
          dob: formatDate(driver.dob),
          guardian_dob: formatDate(driver.guardian_dob as string),
        }
      }
      return {
        ...driver,
        dob: formatDate(driver.dob),
      }
    })
    console.log(driversFormat)
    setDrivers(driversFormat)
    return driversResponse
  }

  const getOrder = useCallback(async () => {
    const extras:Record<string, unknown> = {
      bookingId: bookingId
    }
    try {
      const { data } = await getRequest('getOrder', {
        id: bookingId,
      })

      extras.response = data

      if (data.success) {
        const { line_items, refunds } = data.data

        let orderData: IOrder = data.data

        const filterQuantityZero = line_items.filter(
          (item: any) => item.current_quantity !== 0
        )

        setOrder({ ...orderData, line_items: filterQuantityZero } as IOrder)
        let totalQuantity = 0

        const refundLineItems: IRefundLineItem[] =
          (refunds &&
            refunds?.flatMap(
              (refund: IRefund) => refund.refund_line_items || []
            )) ||
          []
        if (line_items && line_items.length) {
          line_items.forEach((item: IOrderLineItem) => {
            const isItemRefunded =
              refundLineItems &&
              !refundLineItems.some(
                (i: IRefundLineItem) => i.line_item_id === item.id
              )

            if (
              !item.variant_title.includes('Pre-Paid Arcade Card') &&
              !item.variant_title.includes('Booking Protection') &&
              !item.variant_title.includes('Gift Card') &&
              isItemRefunded
            ) {
              totalQuantity += item.quantity
            }
          })
        }

        // console.log('Total Quantity:', totalQuantity)
        dispatch({
          type: 'SET_TOTAL_LINE_ITEMS',
          payload: {
            totalLineItems: totalQuantity,
          },
        })
      } else {
        alert('Error in fetching driver')
      }
    } catch (error) {
      sentryException(error as Error, "error", extras, {function: 'getOrder'})
      alert('Something went wrong')
    }
  }, [bookingId])

  const getBooking = useCallback(async () => {
    const extras:Record<string, unknown> = {
      bookingId: bookingId
    }
    try {
      const { data } = await getRequest('getBooking', {
        id: bookingId,
      })
      extras.response = data
      if (!data.success) {
        return null
      }
      setBooking(data.data)
    } catch (error) {
      sentryException(error as Error, "error", extras, {function: 'getBooking'})
      alert('Something went wrong')
    }
  }, [bookingId])

  useEffect(() => {
    const loadData = async () => {
      if (bookingId && !loading) {
        await Promise.all([getOrder(), getBooking()])
      }
    }

    loadData()
  }, [getOrder, getBooking, bookingId, loading])

  return (
    <RegistrationContext.Provider
      value={{
        orderId: String(bookingId),
        hasError: state.hasError,
        selectedDOB: state.selectedDOB,
        selectedDOBError: state.selectedDOBError,
        order,
        booking,
        drivers,
        totalLineItems: state.totalLineItems,
        navigation: state.navigation,
        editMode: state.editMode,
        driver: {
          ...state.driver,
          email_consent: state.driver?.email_consent,
        } as IDriver,
        itemRegistrationLink: state.itemRegistrationLink,
        juniors: state.juniors,
        isJunior,
        loading,
        isGenericDriverRegistration: isGenericDriverRegistration || false,
        setLoading,
        toggleIsJunior,
        email,
        setEmail,
        getDrivers,
        getBooking,
        dispatch,
      }}
    >
      <div
        className={`h-full bg-black relative z-[0] ${
          loading ? 'overflow-hidden' : ''
        }`}
      >
        <HeaderLogo />
        <Outlet />
        {loading && <Loader />}
      </div>
    </RegistrationContext.Provider>
  )
}

export default RegistrationBaseWrapper
