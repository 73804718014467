import React, { useContext, useEffect } from 'react'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import Header from './Header'
import {
  DRIVER_EXISTING_PROFILE_TITLE,
  BACK_BUTTON_LABEL,
  CREATE_NEW_USER_LABEL,
} from './Constants'
import { PlusIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import DriverListItem from './DriverListItem'

type Props = {
  goToPreviousPage: Function
  openCreateDriverScreen: Function
  openDriverPreview: Function
}

const DriverListScreen = (props: Props) => {
  const { goToPreviousPage, openCreateDriverScreen, openDriverPreview } = props
  const registrationContext = useContext(RegistrationContext)
  const { drivers } = registrationContext as IRegistrationStateValue

  useEffect(() => {
    if (!(drivers && drivers.length)) {
      openCreateDriverScreen()
    }
  }, [drivers, openCreateDriverScreen])

  return (
    <>
      <Header title={DRIVER_EXISTING_PROFILE_TITLE}></Header>
      {drivers.map((driver, idx) => (
        <DriverListItem
          driver={driver}
          openDriverPreview={openDriverPreview}
          key={idx}
        />
      ))}
      <div
        className="pt-3 pr-5 pl-4 pb-3 bg-white border border-slate-200 rounded-lg flex items-center justify-between hover:shadow mb-2.5 cursor-pointer"
        onClick={() => {
          openCreateDriverScreen()
        }}
      >
        <div className="flex items-center">
          <div className="w-[50px] h-[50px] bg-zinc-300 rounded-full mr-2.5 flex items-center justify-center">
            <PlusIcon fill="black" className="w-[24px] h-[24px]" />
          </div>
          <div>
            <div className="text-base leading-5">{CREATE_NEW_USER_LABEL}</div>
          </div>
        </div>
        <div className="">
          <div className="w-[24px] h-[24px] bg-[#5B36DF] rounded-full flex items-center justify-center">
            <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button
          className="text-center self-center w-full items-center p-2 border border-slate-200 text-medium font-semibold rounded shadow-sm. hover:bg-indigo-700 text-white bg-indigo-600"
          onClick={() => {
            goToPreviousPage()
          }}
        >
          {BACK_BUTTON_LABEL}
        </button>
      </div>
    </>
  )
}

export default DriverListScreen
