import { DateTime } from 'luxon'
import { sentryException } from '../helper/sentry'

export function ValidateEmail(email: string) {
  return new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email)
}

export function ValidatePhone(phone: string) {
  return new RegExp(
    /^((\+\d{1,2}\s?)?1?-?.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}|4\d{8})$/
  ).test(phone)
}

export const skuToSessionDate = (sku: string): string => {
  const extras:Record<string, unknown> = {
    sku: sku
  }
  if (!sku) {
    return ''
  }
  const matchSku = sku.match(/(2[2-3][0-1][0-9][0-3][0-9]{3})\w+/g)
  if (matchSku) {
    const matched = matchSku[0]
    const yy = matched.slice(0, 2)
    const mm = matched.slice(2, 4)
    const dd = matched.slice(4, 6)
    let HH = matched.slice(6, 8)
    const MM = matched.slice(8, 10)
    let offset = 0
    const nextDaySession = parseInt(HH) > 23 ? true : false

    try {
      extras.nextDaySession = nextDaySession
      if (nextDaySession) {
        offset = parseInt(HH) - 23
        HH = '23'
        const sessionDate = DateTime.fromISO(
          `20${yy}-${mm}-${dd}T${HH}:${MM}:00`,
          { zone: 'Australia/Sydney' }
        )
        sessionDate.plus({ hours: offset })
        return sessionDate.toString()
      } else {
        const sessionDate = DateTime.fromISO(
          `20${yy}-${mm}-${dd}T${HH}:${MM}:00`,
          { zone: 'Australia/Sydney' }
        )
        return sessionDate.toString()
      }
    } catch (error) {
      sentryException(error as Error, "error", extras, {function: 'skuToSessionDate'})
      console.log('Error parsing date from SKU')
      return ''
    }
  } else {
    return ''
  }
}

export const convertDob = (dob: string) => {
  if (dob) return dob.split('-').reverse().join('-')
  else return null
}
